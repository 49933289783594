import { until } from '@vueuse/core';

export default async (): Promise<string | null> => {
  const { userToken, pendingUserToken, expiration } =
    storeToRefs(useUserStore());

  if (userToken.value && expiration.value && expiration.value > Date.now())
    return userToken.value;

  if (pendingUserToken.value) {
    await until(userToken).not.toBeNull();
    return userToken.value;
  } else {
    useUser();
    await until(userToken).not.toBeNull();
    return userToken.value;
  }
};
